* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
}
.root {
  margin: 0;
}

.title {
  margin: 0;
}

.stackable {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Navbar section*/
.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.navTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.portfolio-title {
  width: 200px;
}

.language-menu {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.navLink {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  font-weight: bold;
}
.main {
  border-top: 2px solid #531dab;
}

/* Project cards section - Repos from github*/
.project-cards-container,
.project-cards-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 1rem;
}

.ant-card-body {
  min-height: 140px;
  max-height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Contact form section */
.contact-title {
  text-align: center;
}
.contact-text {
  margin: 50px;
  text-align: center;
}
.form-control {
  margin: 0 100px;
  align-items: center;
}

/*Resume section*/
.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}
.badge-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.badge-box {
  margin: 10px;
}
.resume-container,
.resume-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.resume-box {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.resume-img {
  max-width: 800px;
}
.resume-title {
  text-align: center;
}

/*Footer styling*/
.footer-container {
  height: 80px;
  font-size: 40px;
  text-align: center;
  border-top: 2px solid #531dab;
}
.footerLink {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: 920px) {
  .stackable {
    display: flex;
    flex-direction: column;
  }
  .navbar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .skills-container {
    margin-top: 50px;
    max-height: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .about-me-container {
    width: 100%;
    padding: 10px;
  }

  .about-text-container {
    margin: 10px 10px 50px 10px;
  }
  .quote-1-container,
  .quote-2-container {
    margin: 50px;
    text-align: center;
  }
  .contact-text {
    margin: 50px 20px;
  }
}

@media screen and (max-width: 596px) {
  .stackable {
    display: flex;
    flex-direction: column;
  }
  .quote-1-container,
  .quote-2-container {
    margin: 0;
    text-align: center;
  }
  .skills-container {
    max-height: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .about-me-container {
    width: 100%;
    padding: 10px;
  }
  .about-text-container {
    margin: 10px 10px 50px 10px;
  }
  .contact-text {
    margin: 10px;
  }
}

@media screen and (max-width: 420px) {
  .stackable {
    display: flex;
    flex-direction: column;
  }
  .quote-1-container,
  .quote-2-container {
    margin: 0;
    text-align: center;
  }
  .skills-container {
    max-height: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .about-me-container {
    width: 100%;
    padding: 10px;
  }
  .about-text-container {
    margin: 10px 10px 50px 10px;
  }
  .contact-text {
    margin: 10px;
  }
}
